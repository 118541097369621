.justify-space-between {
  justify-content: space-between;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.break-all {
  word-break: break-all;
}

.action-btn {
  padding: 0 !important;
}

.file-icon {
  top: 0.1rem;
}
